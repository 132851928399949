import type { FC } from 'react';
type Props = {
  className: string;
};
const WikiIcon: FC<Props> = ({
  className
}) => {
  return <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <rect width="130" height="130" fill="white" />
      <rect x="12" y="110" width="75" height="16" rx="2" fill="#8b5cf6" />
      <path d="M59 110H71V126H59V110Z" fill="#6d28d9" />
      <path d="M96.5141 36.346C97.5661 36.0093 98.6919 36.5891 99.0286 37.6412L124.699 117.851C125.036 118.903 124.456 120.029 123.404 120.365L110.07 124.633C109.018 124.969 107.893 124.39 107.556 123.338L81.8852 43.1278C81.5485 42.0758 82.1284 40.9501 83.1804 40.6134L96.5141 36.346Z" fill="#c4b5fd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M96.2092 35.3935C97.7872 34.8884 99.4758 35.7582 99.9809 37.3362L125.652 117.546C126.157 119.124 125.287 120.813 123.709 121.318L110.375 125.585C108.797 126.09 107.109 125.22 106.603 123.642L80.9326 43.4326C80.4276 41.8545 81.2974 40.1659 82.8754 39.6609L96.2092 35.3935ZM98.076 37.9459C97.9077 37.4199 97.3448 37.1299 96.8188 37.2983L83.485 41.5657C82.959 41.734 82.6691 42.2969 82.8375 42.8229L108.508 123.033C108.677 123.559 109.24 123.849 109.766 123.68L123.099 119.413C123.625 119.244 123.915 118.682 123.747 118.156L98.076 37.9459Z" fill="#6d28d9" />
      <path d="M115.49 90.0762L118.728 100.192L101.584 105.678L98.3468 95.5629L115.49 90.0762Z" fill="#c4b5fd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M115.483 89.1809C116.007 89.013 116.569 89.3034 116.737 89.8294L119.975 99.9448C120.143 100.471 119.854 101.033 119.33 101.201L102.235 106.672C101.71 106.84 101.149 106.55 100.98 106.024L97.7428 95.9085C97.5745 95.3825 97.8632 94.82 98.3878 94.6522L115.483 89.1809ZM115.143 91.3897L99.9471 96.253L102.575 104.464L117.771 99.6003L115.143 91.3897Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.7629 70.8492C22.1409 69.523 23.1088 68 24.5737 68H74.0002C78.4184 68 82.0002 71.5817 82.0002 76C82.0002 80.4183 78.4184 84 74.0002 84H24.5737C23.1088 84 22.1409 82.477 22.7629 81.1508L23.7846 78.9723C24.6678 77.0892 24.6678 74.9108 23.7846 73.0276L22.7629 70.8492ZM74.0002 70H24.5737L25.5954 72.1784C26.7309 74.5996 26.7309 77.4004 25.5954 79.8216L24.5737 82H74.0002C77.3139 82 80.0002 79.3137 80.0002 76C80.0002 72.6863 77.3139 70 74.0002 70Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M28 76C28 75.4477 28.4477 75 29 75H73C73.5523 75 74 75.4477 74 76C74 76.5523 73.5523 77 73 77H29C28.4477 77 28 76.5523 28 76Z" fill="#c4b5fd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9565 55.9745C16.2127 54.6414 17.1765 53 18.7031 53H79C83.4183 53 87 56.5817 87 61C87 65.4183 83.4183 69 79 69H18.7031C17.1765 69 16.2127 67.3586 16.9565 66.0255L17.8575 64.4107C19.0404 62.2907 19.0404 59.7093 17.8575 57.5893L16.9565 55.9745ZM79 55L18.7031 55L19.6041 56.6148C21.1249 59.3405 21.1249 62.6595 19.6041 65.3852L18.7031 67H79C82.3137 67 85 64.3137 85 61C85 57.6863 82.3137 55 79 55Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22 61C22 60.4477 22.4477 60 23 60H61C61.5523 60 62 60.4477 62 61C62 61.5523 61.5523 62 61 62H23C22.4477 62 22 61.5523 22 61Z" fill="#c4b5fd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1 126C1 124.895 1.89543 124 3 124H127C128.105 124 129 124.895 129 126C129 127.105 128.105 128 127 128H3C1.89543 128 1 127.105 1 126Z" fill="#6d28d9" />
      <path d="M24.5621 23.012L48.7834 11.2152C49.062 11.0795 49.3879 11.0807 49.6655 11.2184L73.4443 23.0102C73.7847 23.179 74 23.5261 74 23.906V29.4538C74 30.1793 73.2515 30.6634 72.5898 30.3658L49.6317 20.04C49.373 19.9236 49.077 19.9226 48.8175 20.0373L25.4041 30.3798C24.743 30.6718 24 30.1878 24 29.4651V23.911C24 23.5285 24.2182 23.1795 24.5621 23.012Z" fill="#8b5cf6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M48.3455 10.3162C48.9028 10.0448 49.5545 10.0471 50.1098 10.3225L73.8885 22.1143C74.5693 22.4519 75 23.1461 75 23.906V29.4538C75 30.9049 73.5031 31.873 72.1796 31.2778L49.2216 20.952L25.8081 31.2945L25.4041 30.3798L25.8081 31.2945C24.486 31.8786 23 30.9104 23 29.4651V23.911C23 23.1459 23.4364 22.4479 24.1243 22.1129L24.4632 22.8088L24.1243 22.1129L48.3455 10.3162L48.7834 11.2152L48.3455 10.3162ZM49.2212 12.1143L25 23.911L25 29.4651L48.4134 19.1225C48.9325 18.8933 49.5244 18.8953 50.0419 19.128L73 29.4538V23.906L49.2212 12.1143Z" fill="#6d28d9" />
      <path d="M31 12H39V19L31 23V12Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.5801 51.1469V28.5762H28.5801V51.1469C28.5801 51.6992 29.0278 52.1469 29.5801 52.1469H68.5376C69.0898 52.1469 69.5376 51.6992 69.5376 51.1469V28.5762H71.5376V51.1469C71.5376 52.8037 70.1944 54.1469 68.5376 54.1469H29.5801C27.9232 54.1469 26.5801 52.8037 26.5801 51.1469Z" fill="#6d28d9" />
      <path d="M48.2754 33.2207C48.2754 32.1161 49.1708 31.2207 50.2754 31.2207H60.3708C61.4754 31.2207 62.3708 32.1161 62.3708 33.2207V51.93C62.3708 53.0346 61.4754 53.93 60.3708 53.93H50.2754C49.1708 53.93 48.2754 53.0346 48.2754 51.93V33.2207Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.2754 33.2207C47.2754 31.5638 48.6185 30.2207 50.2754 30.2207H60.3708C62.0277 30.2207 63.3708 31.5638 63.3708 33.2207V51.93C63.3708 53.5869 62.0277 54.93 60.3708 54.93H50.2754C48.6185 54.93 47.2754 53.5869 47.2754 51.93V33.2207ZM50.2754 32.2207C49.7231 32.2207 49.2754 32.6684 49.2754 33.2207V51.93C49.2754 52.4823 49.7231 52.93 50.2754 52.93H60.3708C60.9231 52.93 61.3708 52.4823 61.3708 51.93V33.2207C61.3708 32.6684 60.9231 32.2207 60.3708 32.2207H50.2754Z" fill="#6d28d9" />
      <circle cx="60.0212" cy="42.967" r="1.56616" fill="white" />
      <rect x="33" y="32" width="11" height="9" rx="2" fill="#6d28d9" />
      <path d="M7 85C7 83.8954 7.89543 83 9 83H92C93.1046 83 94 83.8954 94 85V108C94 109.105 93.1046 110 92 110H9C7.89543 110 7 109.105 7 108V85Z" fill="#c4b5fd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6 85C6 83.3431 7.34315 82 9 82H92C93.6569 82 95 83.3431 95 85V108C95 109.657 93.6569 111 92 111H9C7.34314 111 6 109.657 6 108V85ZM9 84C8.44772 84 8 84.4477 8 85V108C8 108.552 8.44772 109 9 109H92C92.5523 109 93 108.552 93 108V85C93 84.4477 92.5523 84 92 84H9Z" fill="#6d28d9" />
      <path d="M23.1865 83H37.3493V110H23.1865V83Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.1865 82H38.3493V111H22.1865V82ZM24.1865 84V109H36.3493V84H24.1865Z" fill="#6d28d9" />
    </svg>;
};
export default WikiIcon;