import Image from 'next/image';
export const Loading = ({
  className = ''
}: {
  className?: string;
}) => {
  return <div className={`${className} relative flex justify-center items-center`}>
      <div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-violet-bds"></div>
      <Image src={'/images/logo/Logo-BDS-369.svg'} width={500} height={500} alt="loading" className="h-16 w-16" />
    </div>;
};