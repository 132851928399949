import type { FC } from 'react';
type Props = {
  className: string;
};
const ForSaleIcon: FC<Props> = ({
  className
}) => {
  return <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <rect width="130" height="130" fill="white" />
      <path d="M15 47.1228V124.245C15 125.214 15.8954 126 17 126H45C46.1046 126 47 125.214 47 124.245V40.9859C47 40.3637 46.6248 39.788 46.0138 39.4726L37.8181 35.2423C37.1885 34.9173 36.4071 34.9194 35.7798 35.2478L15.9755 45.6152C15.3706 45.9319 15 46.5046 15 47.1228Z" fill="#8b5cf6" />
      <path d="M26 53.5714C26 52.7036 26.7577 52 27.6923 52H35.3077C36.2423 52 37 52.7036 37 53.5714V61.4286C37 62.2964 36.2423 63 35.3077 63H27.6923C26.7577 63 26 62.2964 26 61.4286V53.5714Z" fill="#6d28d9" />
      <path d="M26 69.5714C26 68.7036 26.7577 68 27.6923 68H35.3077C36.2423 68 37 68.7036 37 69.5714V77.4286C37 78.2964 36.2423 79 35.3077 79H27.6923C26.7577 79 26 78.2964 26 77.4286V69.5714Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M49 9C48.4477 9 48 9.44772 48 10V77C48 77.5523 47.5523 78 47 78C46.4477 78 46 77.5523 46 77V10C46 8.34314 47.3431 7 49 7H105C106.657 7 108 8.34314 108 10V67.5C108 68.0523 107.552 68.5 107 68.5C106.448 68.5 106 68.0523 106 67.5V10C106 9.44772 105.552 9 105 9H49Z" fill="#6d28d9" />
      <path d="M56 22C56 21.4477 56.4477 21 57 21H67C67.5523 21 68 21.4477 68 22V36C68 36.5523 67.5523 37 67 37H57C56.4477 37 56 36.5523 56 36V22Z" fill="#8b5cf6" />
      <path d="M71 22C71 21.4477 71.4477 21 72 21H82C82.5523 21 83 21.4477 83 22V36C83 36.5523 82.5523 37 82 37H72C71.4477 37 71 36.5523 71 36V22Z" fill="#8b5cf6" />
      <path d="M86 22C86 21.4477 86.4477 21 87 21H97C97.5523 21 98 21.4477 98 22V36C98 36.5523 97.5523 37 97 37H87C86.4477 37 86 36.5523 86 36V22Z" fill="#8b5cf6" />
      <path d="M56 44C56 43.4477 56.4477 43 57 43H67C67.5523 43 68 43.4477 68 44V58C68 58.5523 67.5523 59 67 59H57C56.4477 59 56 58.5523 56 58V44Z" fill="#8b5cf6" />
      <path d="M71 45C71 43.8954 71.8954 43 73 43H81C82.1046 43 83 43.8954 83 45V57C83 58.1046 82.1046 59 81 59H73C71.8954 59 71 58.1046 71 57V45Z" fill="#6d28d9" />
      <path d="M86 45C86 43.8954 86.8954 43 88 43H96C97.1046 43 98 43.8954 98 45V57C98 58.1046 97.1046 59 96 59H88C86.8954 59 86 58.1046 86 57V45Z" fill="#6d28d9" />
      <path d="M25.4082 107.224C25.4082 116.241 20.8396 118.653 15.2041 118.653C9.56852 118.653 5 116.241 5 107.224C5 98.2076 11.2692 86 15.2041 86C19.139 86 25.4082 98.2076 25.4082 107.224Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.52348 93.6729C7.51001 97.7592 6 102.912 6 107.224C6 111.577 7.10129 114.118 8.67241 115.59C10.2549 117.073 12.5065 117.653 15.2041 117.653C17.9017 117.653 20.1533 117.073 21.7358 115.59C23.3069 114.118 24.4082 111.577 24.4082 107.224C24.4082 102.912 22.8982 97.7592 20.8847 93.6729C19.8802 91.6344 18.774 89.9099 17.7107 88.7122C17.1789 88.1133 16.6796 87.6707 16.2325 87.3839C15.7805 87.0941 15.4397 87 15.2041 87C14.9685 87 14.6277 87.0941 14.1757 87.3839C13.7286 87.6707 13.2292 88.1133 12.6975 88.7122C11.6342 89.9099 10.5279 91.6344 9.52348 93.6729ZM13.0961 85.7004C13.7401 85.2874 14.456 85 15.2041 85C15.9522 85 16.6681 85.2874 17.3121 85.7004C17.961 86.1165 18.597 86.698 19.2063 87.3844C20.4252 88.7574 21.624 90.6484 22.6787 92.7889C24.7836 97.0607 26.4082 102.52 26.4082 107.224C26.4082 111.889 25.2252 115.062 23.1032 117.05C20.9925 119.027 18.142 119.653 15.2041 119.653C12.2661 119.653 9.41567 119.027 7.305 117.05C5.18297 115.062 4 111.889 4 107.224C4 102.52 5.62459 97.0607 7.72944 92.7889C8.78417 90.6484 9.98297 88.7574 11.2018 87.3844C11.8112 86.698 12.4472 86.1165 13.0961 85.7004Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.4795 99.877C16.3079 99.877 16.9795 100.549 16.9795 101.377L16.9795 124.499C16.9795 125.328 16.3079 125.999 15.4795 125.999C14.6511 125.999 13.9795 125.328 13.9795 124.499L13.9795 101.377C13.9795 100.549 14.6511 99.877 15.4795 99.877Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.19133 107.15C9.58186 106.76 10.215 106.76 10.6055 107.15L15.5035 112.048C15.894 112.439 15.894 113.072 15.5035 113.463C15.113 113.853 14.4798 113.853 14.0893 113.463L9.19133 108.565C8.80081 108.174 8.80081 107.541 9.19133 107.15Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.8087 103.252C21.1992 103.643 21.1992 104.276 20.8087 104.666L16.3189 109.156C15.9283 109.547 15.2952 109.547 14.9047 109.156C14.5141 108.766 14.5141 108.132 14.9047 107.742L19.3945 103.252C19.785 102.862 20.4181 102.862 20.8087 103.252Z" fill="#6d28d9" />
      <path d="M60 103C60 114.046 54.4036 117 47.5 117C40.5964 117 35 114.046 35 103C35 91.9543 42.6798 77 47.5 77C52.3202 77 60 91.9543 60 103Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.3394 86.2999C37.8627 91.3264 36 97.6735 36 103C36 108.367 37.3583 111.551 39.3449 113.412C41.3428 115.284 44.1684 116 47.5 116C50.8316 116 53.6572 115.284 55.6551 113.412C57.6417 111.551 59 108.367 59 103C59 97.6735 57.1373 91.3264 54.6606 86.2999C53.4245 83.7912 52.0589 81.66 50.7388 80.1731C50.0787 79.4295 49.4517 78.8717 48.8812 78.5059C48.3059 78.137 47.8463 78 47.5 78C47.1537 78 46.6941 78.137 46.1188 78.5059C45.5483 78.8717 44.9213 79.4295 44.2612 80.1731C42.9411 81.66 41.5755 83.7912 40.3394 86.2999ZM45.0392 76.8224C45.8065 76.3303 46.6412 76 47.5 76C48.3588 76 49.1935 76.3303 49.9608 76.8224C50.733 77.3175 51.4968 78.0143 52.2345 78.8453C53.7101 80.5075 55.1682 82.8052 56.4546 85.4159C59.0228 90.6279 61 97.2808 61 103C61 108.679 59.5601 112.495 57.0225 114.872C54.4964 117.239 51.072 118 47.5 118C43.928 118 40.5036 117.239 37.9775 114.872C35.4399 112.495 34 108.679 34 103C34 97.2808 35.9772 90.6279 38.5454 85.4159C39.8318 82.8052 41.2899 80.5075 42.7655 78.8453C43.5032 78.0143 44.267 77.3175 45.0392 76.8224Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.5 89C48.3284 89 49 89.6716 49 90.5L49 124.5C49 125.328 48.3284 126 47.5 126C46.6716 126 46 125.328 46 124.5L46 90.5C46 89.6716 46.6716 89 47.5 89Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M40.2929 100.293C40.6834 99.9025 41.3166 99.9025 41.7071 100.293L47.7071 106.293C48.0976 106.684 48.0976 107.317 47.7071 107.707C47.3166 108.098 46.6834 108.098 46.2929 107.707L40.2929 101.707C39.9024 101.317 39.9024 100.684 40.2929 100.293Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M54.2071 95.293C54.5976 95.6836 54.5976 96.3167 54.2071 96.7073L48.7071 102.207C48.3166 102.598 47.6834 102.598 47.2929 102.207C46.9024 101.817 46.9024 101.184 47.2929 100.793L52.7929 95.293C53.1834 94.9025 53.8166 94.9025 54.2071 95.293Z" fill="#6d28d9" />
      <path d="M72 76.7145C72 75.9207 72.4695 75.202 73.1964 74.8831L97.1964 64.3526C97.7086 64.1279 98.2914 64.1279 98.8036 64.3526L122.804 74.8831C123.531 75.202 124 75.9207 124 76.7145V124C124 125.105 123.105 126 122 126H74C72.8954 126 72 125.105 72 124V76.7145Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M98.4018 65.2684C98.1457 65.156 97.8543 65.156 97.5982 65.2684L73.5982 75.7988C73.2347 75.9583 73 76.3176 73 76.7145V124C73 124.552 73.4477 125 74 125H122C122.552 125 123 124.552 123 124V76.7145C123 76.3176 122.765 75.9583 122.402 75.7988L98.4018 65.2684ZM96.7946 63.4369C97.5629 63.0998 98.4371 63.0998 99.2054 63.4369L123.205 73.9674C124.296 74.4458 125 75.5238 125 76.7145V124C125 125.657 123.657 127 122 127H74C72.3431 127 71 125.657 71 124V76.7145C71 75.5238 71.7042 74.4458 72.7946 73.9674L96.7946 63.4369Z" fill="#6d28d9" />
      <path d="M82 86C82 84.8954 82.8954 84 84 84H94C95.1046 84 96 84.8954 96 86V94C96 95.1046 95.1046 96 94 96H84C82.8954 96 82 95.1046 82 94V86Z" fill="#6d28d9" />
      <path d="M81 104C81 102.895 81.8954 102 83 102H113C114.105 102 115 102.895 115 104V124C115 125.105 114.105 126 113 126H83C81.8954 126 81 125.105 81 124V104Z" fill="#c4b5fd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M80 104C80 102.343 81.3431 101 83 101H113C114.657 101 116 102.343 116 104V124C116 125.657 114.657 127 113 127H83C81.3431 127 80 125.657 80 124V104ZM83 103C82.4477 103 82 103.448 82 104V124C82 124.552 82.4477 125 83 125H113C113.552 125 114 124.552 114 124V104C114 103.448 113.552 103 113 103H83Z" fill="#6d28d9" />
      <path d="M100 86C100 84.8954 100.895 84 102 84H112C113.105 84 114 84.8954 114 86V94C114 95.1046 113.105 96 112 96H102C100.895 96 100 95.1046 100 94V86Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M98 102C98.5523 102 99 102.448 99 103L99 125C99 125.552 98.5523 126 98 126C97.4477 126 97 125.552 97 125L97 103C97 102.448 97.4477 102 98 102Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1 126C1 124.895 1.89543 124 3 124H127C128.105 124 129 124.895 129 126C129 127.105 128.105 128 127 128H3C1.89543 128 1 127.105 1 126Z" fill="#6d28d9" />
    </svg>;
};
export default ForSaleIcon;