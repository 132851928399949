import type { FC } from 'react';
type Props = {
  className: string;
};
const ForRentIcon: FC<Props> = ({
  className
}) => {
  return <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <rect width="130" height="130" fill="white" />
      <path d="M56.4964 85.6044L87.7801 67.3666C88.0938 67.1837 88.4819 67.1852 88.7942 67.3706L119.51 85.6032C119.814 85.7833 120 86.1101 120 86.4631V96.0391C120 96.7957 119.192 97.2782 118.526 96.9196L88.7581 80.8936C88.4645 80.7355 88.1114 80.7342 87.8166 80.8901L57.4674 96.9372C56.8014 97.2894 56 96.8066 56 96.0532V86.4683C56 86.1125 56.189 85.7836 56.4964 85.6044Z" fill="#8b5cf6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M87.2764 66.5026C87.9038 66.1369 88.6801 66.14 89.3046 66.5106L120.021 84.7432C120.628 85.1035 121 85.7572 121 86.4631V96.0391C121 97.5523 119.384 98.5174 118.052 97.8001L88.284 81.7741L57.9349 97.8213C56.6029 98.5255 55 97.5599 55 96.0532V86.4683C55 85.7568 55.378 85.0988 55.9927 84.7405L55.9927 84.7405L87.2764 66.5026ZM88.2837 68.2305L57 86.4683L56.4964 85.6044L57 86.4683L57 96.0532L87.3492 80.0061C87.9388 79.6943 88.6449 79.697 89.2321 80.0131L88.7581 80.8936L89.2321 80.0131L119 96.0391V86.4631L88.2837 68.2305Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M59.5713 124V94.623H61.5713V124C61.5713 124.552 62.019 125 62.5713 125H113.428C113.981 125 114.428 124.552 114.428 124V94.623H116.428V124C116.428 125.657 115.085 127 113.428 127H62.5713C60.9144 127 59.5713 125.657 59.5713 124Z" fill="#6d28d9" />
      <path d="M21 12C21 10.8954 21.8954 10 23 10H27C28.1046 10 29 10.8954 29 12V124C29 125.105 28.1046 126 27 126H23C21.8954 126 21 125.105 21 124V12Z" fill="#c4b5fd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M37 34C37 32.3431 38.3431 31 40 31H108C109.657 31 111 32.3431 111 34V58C111 59.6569 109.657 61 108 61H40C38.3431 61 37 59.6569 37 58V34ZM40 33C39.4477 33 39 33.4477 39 34V58C39 58.5523 39.4477 59 40 59H108C108.552 59 109 58.5523 109 58V34C109 33.4477 108.552 33 108 33H40Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M44 32V21H48V32H44Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M100 32V21H104V32H100Z" fill="#6d28d9" />
      <path d="M11 23C11 21.8954 11.8954 21 13 21H117C118.105 21 119 21.8954 119 23C119 24.1046 118.105 25 117 25H13C11.8954 25 11 24.1046 11 23Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 23C10 21.3431 11.3431 20 13 20H117C118.657 20 120 21.3431 120 23C120 24.6569 118.657 26 117 26H13C11.3431 26 10 24.6569 10 23ZM13 22C12.4477 22 12 22.4477 12 23C12 23.5523 12.4477 24 13 24H117C117.552 24 118 23.5523 118 23C118 22.4477 117.552 22 117 22H13Z" fill="#6d28d9" />
      <path d="M51.6583 54.9186C51.1635 54.9186 50.7607 54.7762 50.4496 54.4914C50.1528 54.2066 50.0043 53.8269 50.0043 53.3522V43.0589H46.5692C45.5231 43.0589 45 42.6249 45 41.757C45 40.9026 45.5231 40.4754 46.5692 40.4754H56.7475C57.7936 40.4754 58.3167 40.9026 58.3167 41.757C58.3167 42.6249 57.7936 43.0589 56.7475 43.0589H53.3123V53.3522C53.3123 53.8269 53.1639 54.2066 52.867 54.4914C52.5701 54.7762 52.1672 54.9186 51.6583 54.9186Z" fill="#8b5cf6" />
      <path d="M71.3538 40.3737C71.8486 40.3737 72.2444 40.5161 72.5413 40.8009C72.8382 41.0857 72.9866 41.4654 72.9866 41.94V53.3522C72.9866 53.8269 72.8382 54.2134 72.5413 54.5118C72.2444 54.7966 71.8486 54.939 71.3538 54.939C70.8591 54.939 70.4632 54.7966 70.1664 54.5118C69.8836 54.227 69.7423 53.8405 69.7423 53.3522V48.7955H62.8507V53.3522C62.8507 53.8269 62.7022 54.2134 62.4054 54.5118C62.1085 54.7966 61.7198 54.939 61.2391 54.939C60.7443 54.939 60.3485 54.7966 60.0516 54.5118C59.7548 54.227 59.6063 53.8405 59.6063 53.3522V41.94C59.6063 41.4654 59.7548 41.0857 60.0516 40.8009C60.3485 40.5161 60.7443 40.3737 61.2391 40.3737C61.7339 40.3737 62.1227 40.5161 62.4054 40.8009C62.7022 41.0857 62.8507 41.4654 62.8507 41.94V46.273H69.7423V41.94C69.7423 41.4654 69.8836 41.0857 70.1664 40.8009C70.4632 40.5161 70.8591 40.3737 71.3538 40.3737Z" fill="#8b5cf6" />
      <path d="M82.1733 55C80.0811 55 78.4836 54.4847 77.381 53.454C76.2925 52.4097 75.7482 50.8908 75.7482 48.8972V41.94C75.7482 41.4654 75.8966 41.0857 76.1935 40.8009C76.4904 40.5161 76.8862 40.3737 77.381 40.3737C77.8758 40.3737 78.2645 40.5161 78.5472 40.8009C78.8441 41.0857 78.9926 41.4654 78.9926 41.94V49.0396C78.9926 50.1788 79.2611 51.0468 79.7983 51.6435C80.3497 52.2402 81.1413 52.5385 82.1733 52.5385C83.2053 52.5385 83.9969 52.2402 84.5482 51.6435C85.0996 51.0332 85.3752 50.1652 85.3752 49.0396V41.94C85.3752 41.4654 85.5166 41.0857 85.7993 40.8009C86.0962 40.5161 86.492 40.3737 86.9868 40.3737C87.4816 40.3737 87.8774 40.5161 88.1743 40.8009C88.4711 41.0857 88.6196 41.4654 88.6196 41.94V48.8972C88.6196 50.8772 88.0682 52.3894 86.9656 53.4336C85.8629 54.4779 84.2655 55 82.1733 55Z" fill="#8b5cf6" />
      <path d="M92.9243 54.8169C92.4295 54.8169 92.0478 54.6881 91.7793 54.4304C91.5107 54.1727 91.3764 53.8066 91.3764 53.3319V41.9604C91.3764 41.4857 91.5107 41.1196 91.7793 40.8619C92.0478 40.6042 92.4295 40.4754 92.9243 40.4754H100.431C101.477 40.4754 102 40.8822 102 41.6959C102 42.5232 101.477 42.9368 100.431 42.9368H94.5995V46.2934H100.007C101.053 46.2934 101.576 46.707 101.576 47.5343C101.576 48.348 101.053 48.7548 100.007 48.7548H94.5995V52.3555H100.431C101.477 52.3555 102 52.7691 102 53.5964C102 54.4101 101.477 54.8169 100.431 54.8169H92.9243ZM95.4477 39.031C95.2356 39.2345 94.9883 39.3362 94.7055 39.3362C94.4369 39.3362 94.2107 39.2548 94.027 39.0921C93.8432 38.9158 93.7513 38.7123 93.7513 38.4818C93.7513 38.2241 93.8785 37.9936 94.133 37.7902L95.8294 36.4069C96.1545 36.1356 96.515 36 96.9108 36C97.3067 36 97.6671 36.1356 97.9923 36.4069L99.6887 37.7902C99.9431 37.9936 100.07 38.2241 100.07 38.4818C100.07 38.7123 99.9785 38.9158 99.7947 39.0921C99.6109 39.2548 99.3918 39.3362 99.1373 39.3362C98.8546 39.3362 98.6002 39.2345 98.374 39.031L96.9108 37.6681L95.4477 39.031Z" fill="#8b5cf6" />
      <path d="M87 100C87 98.8954 87.8954 98 89 98H103C104.105 98 105 98.8954 105 100V125C105 126.105 104.105 127 103 127H89C87.8954 127 87 126.105 87 125V100Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M86 100C86 98.3431 87.3431 97 89 97H103C104.657 97 106 98.3431 106 100V125C106 126.657 104.657 128 103 128H89C87.3431 128 86 126.657 86 125V100ZM89 99C88.4477 99 88 99.4477 88 100V125C88 125.552 88.4477 126 89 126H103C103.552 126 104 125.552 104 125V100C104 99.4477 103.552 99 103 99H89Z" fill="#6d28d9" />
      <path d="M104 113C104 114.105 103.105 115 102 115C100.895 115 100 114.105 100 113C100 111.895 100.895 111 102 111C103.105 111 104 111.895 104 113Z" fill="white" />
      <path d="M68 101C68 99.8954 68.8954 99 70 99H78C79.1046 99 80 99.8954 80 101V109C80 110.105 79.1046 111 78 111H70C68.8954 111 68 110.105 68 109V101Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M67 101C67 99.3431 68.3431 98 70 98H78C79.6569 98 81 99.3431 81 101V109C81 110.657 79.6569 112 78 112H70C68.3431 112 67 110.657 67 109V101ZM70 100C69.4477 100 69 100.448 69 101V109C69 109.552 69.4477 110 70 110H78C78.5523 110 79 109.552 79 109V101C79 100.448 78.5523 100 78 100H70Z" fill="#6d28d9" />
      <path d="M64 71H73V80.2308L64 86V71Z" fill="#6d28d9" />
      <path d="M70.0002 126H9.00024C7.89567 126 7.01264 125.1 7.24417 124.02C8.10796 119.991 11.4856 113.032 20.7909 112.621C21.3453 112.596 21.8873 112.359 22.2738 111.961C35.545 98.2865 46.6747 105.332 51.4563 110.282C51.9238 110.766 52.595 111.011 53.2638 110.937C68.2924 109.275 71.4342 117.884 71.9229 124.004C72.0108 125.105 71.1048 126 70.0002 126Z" fill="#8b5cf6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1 126C1 124.895 1.89543 124 3 124H127C128.105 124 129 124.895 129 126C129 127.105 128.105 128 127 128H3C1.89543 128 1 127.105 1 126Z" fill="#6d28d9" />
    </svg>;
};
export default ForRentIcon;