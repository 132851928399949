import type { FC } from 'react';
type Props = {
  className: string;
};
const ProjectIcon: FC<Props> = ({
  className
}) => {
  return <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <rect width="130" height="130" fill="white" />
      <path d="M12 112H117V126H12V112Z" fill="#8b5cf6" />
      <path d="M27.2846 36.329L43.2244 43.0146C43.992 43.3365 44.4799 44.1001 44.4495 44.9319L42.0704 110.073C42.0311 111.148 41.1479 112 40.0717 112H11C9.89543 112 9 111.104 9 110V44.8649C9 44.0666 9.47475 43.3448 10.2078 43.0285L25.7188 36.3369C26.218 36.1216 26.7833 36.1187 27.2846 36.329Z" fill="#c4b5fd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M25.3227 35.4187C26.0714 35.0957 26.9194 35.0914 27.6714 35.4068L43.6112 42.0924C44.7625 42.5753 45.4944 43.7208 45.4488 44.9684L43.0697 110.109C43.0108 111.723 41.686 113 40.0717 113H11C9.34315 113 8 111.657 8 110V44.8649C8 43.6675 8.71212 42.5847 9.81164 42.1104L10.2078 43.0285L9.81164 42.1104L25.3227 35.4187ZM26.8978 37.2512C26.6471 37.146 26.3645 37.1475 26.1149 37.2551L10.6039 43.9467C10.2374 44.1049 10 44.4658 10 44.8649V110C10 110.552 10.4477 111 11 111H40.0717C40.6098 111 41.0514 110.574 41.071 110.036L43.4502 44.8954C43.4654 44.4795 43.2214 44.0977 42.8376 43.9367L26.8978 37.2512Z" fill="#6d28d9" />
      <path d="M17.8164 48H25.1633V57.1837H17.8164V48Z" fill="#6d28d9" />
      <path d="M17.8164 59.939H25.1633V69.1226H17.8164V59.939Z" fill="#8b5cf6" />
      <path d="M17.8164 71.8774H25.1633V81.0611H17.8164V71.8774Z" fill="white" />
      <path d="M17.8164 83.8164H25.1633V93.0001H17.8164V83.8164Z" fill="white" />
      <path d="M28.8369 48H36.1839V57.1837H28.8369V48Z" fill="#8b5cf6" />
      <path d="M28.8369 59.939H36.1839V69.1226H28.8369V59.939Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.9241 9C47.3869 9 46.9457 9.42441 46.9248 9.96119L42.9995 111.039L41.001 110.961L44.9263 9.88358C44.9889 8.27323 46.3125 7 47.9241 7H84.1404C85.7128 7 87.0184 8.21411 87.1325 9.78239L88.9976 35.4275L87.0029 35.5725L85.1378 9.92746C85.0997 9.4047 84.6645 9 84.1404 9H47.9241Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M77 37C76.4477 37 76 37.4477 76 38V102.048H74V38C74 36.3431 75.3431 35 77 35H119C120.657 35 122 36.3431 122 38V110C122 111.657 120.657 113 119 113H36.5V111H119C119.552 111 120 110.552 120 110V38C120 37.4477 119.552 37 119 37H77Z" fill="#6d28d9" />
      <path d="M81 44C81 43.4477 81.4477 43 82 43H114C114.552 43 115 43.4477 115 44V48C115 48.5523 114.552 49 114 49H82C81.4477 49 81 48.5523 81 48V44Z" fill="#c4b5fd" />
      <path d="M81 55C81 54.4477 81.4477 54 82 54H114C114.552 54 115 54.4477 115 55V59C115 59.5523 114.552 60 114 60H82C81.4477 60 81 59.5523 81 59V55Z" fill="#8b5cf6" />
      <path d="M81 66C81 65.4477 81.4477 65 82 65H114C114.552 65 115 65.4477 115 66V70C115 70.5523 114.552 71 114 71H82C81.4477 71 81 70.5523 81 70V66Z" fill="#c4b5fd" />
      <path d="M81 77C81 76.4477 81.4477 76 82 76H114C114.552 76 115 76.4477 115 77V81C115 81.5523 114.552 82 114 82H82C81.4477 82 81 81.5523 81 81V77Z" fill="#8b5cf6" />
      <path d="M63.6211 18H69.6211V28H63.6211V18Z" fill="#8b5cf6" />
      <path d="M74 18H80V28H74V18Z" fill="#8b5cf6" />
      <path d="M63.6211 31H69.6211V41H63.6211V31Z" fill="#8b5cf6" />
      <path d="M63.6211 44H69.6211V54H63.6211V44Z" fill="#c4b5fd" />
      <path d="M63.6211 57H69.6211V67H63.6211V57Z" fill="#c4b5fd" />
      <path d="M63.6211 70H69.6211V80H63.6211V70Z" fill="#c4b5fd" />
      <path d="M53 18H59V28H53V18Z" fill="#6d28d9" />
      <path d="M53 31H59V41H53V31Z" fill="#8b5cf6" />
      <path d="M53 44H59V54H53V44Z" fill="#8b5cf6" />
      <path d="M53 57H59V67H53V57Z" fill="#c4b5fd" />
      <path fillRule="evenodd" clipRule="evenodd" d="M124 127.5H6V124.5H124V127.5Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1 126C1 124.895 1.89543 124 3 124H127C128.105 124 129 124.895 129 126C129 127.105 128.105 128 127 128H3C1.89543 128 1 127.105 1 126Z" fill="#6d28d9" />
      <path d="M92 90H77C70.425 90 67.1376 90 64.9249 91.8159C64.5198 92.1484 64.1484 92.5198 63.8159 92.9249C62 95.1376 62 98.425 62 105C62 111.575 62 114.862 63.8159 117.075C64.1484 117.48 64.5198 117.852 64.9249 118.184C67.1376 120 70.425 120 77 120H92C98.575 120 101.862 120 104.075 118.184C104.48 117.852 104.852 117.48 105.184 117.075C107 114.862 107 111.575 107 105C107 98.425 107 95.1376 105.184 92.9249C104.852 92.5198 104.48 92.1484 104.075 91.8159C101.862 90 98.575 90 92 90Z" fill="#6d28d9" />
      <path d="M90 103.268C91.3333 104.038 91.3333 105.962 90 106.732L81 111.928C79.6667 112.698 78 111.736 78 110.196L78 99.8038C78 98.2642 79.6667 97.3019 81 98.0717L90 103.268Z" fill="white" />
      <path d="M53 105.76C53 115.48 48.0751 118.08 42 118.08C35.9249 118.08 31 115.48 31 105.76C31 96.0397 37.7582 82.8799 42 82.8799C46.2418 82.8799 53 96.0397 53 105.76Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.8063 91.1168C33.6323 95.529 32 101.096 32 105.76C32 110.464 33.1904 113.228 34.9055 114.835C36.632 116.453 39.0826 117.08 42 117.08C44.9174 117.08 47.368 116.453 49.0945 114.835C50.8096 113.228 52 110.464 52 105.76C52 101.096 50.3677 95.529 48.1937 91.1168C47.1089 88.9153 45.9127 87.0499 44.7604 85.7519C44.1842 85.1028 43.6406 84.6203 43.1507 84.3061C42.656 83.9889 42.274 83.8799 42 83.8799C41.726 83.8799 41.344 83.9889 40.8493 84.3061C40.3594 84.6203 39.8158 85.1028 39.2396 85.7519C38.0873 87.0499 36.8911 88.9153 35.8063 91.1168ZM39.7697 82.6225C40.4564 82.1821 41.2135 81.8799 42 81.8799C42.7865 81.8799 43.5436 82.1821 44.2303 82.6225C44.9219 83.066 45.6022 83.6876 46.2561 84.4241C47.564 85.8973 48.8527 87.9293 49.9877 90.2328C52.2532 94.8305 54 100.703 54 105.76C54 110.776 52.7279 114.172 50.4619 116.295C48.2072 118.407 45.1577 119.08 42 119.08C38.8423 119.08 35.7928 118.407 33.5381 116.295C31.2721 114.172 30 110.776 30 105.76C30 100.703 31.7468 94.8305 34.0123 90.2328C35.1473 87.9293 36.436 85.8973 37.7439 84.4241C38.3978 83.6876 39.0781 83.066 39.7697 82.6225Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M42.1797 93.4399C43.0081 93.4399 43.6797 94.1115 43.6797 94.9399L43.6797 124.5C43.6797 125.328 43.0081 126 42.1797 126C41.3513 126 40.6797 125.328 40.6797 124.5L40.6797 94.9399C40.6797 94.1115 41.3513 93.4399 42.1797 93.4399Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.5732 103.293C35.9637 102.903 36.5969 102.903 36.9874 103.293L42.2674 108.573C42.6579 108.964 42.6579 109.597 42.2674 109.987C41.8769 110.378 41.2437 110.378 40.8532 109.987L35.5732 104.707C35.1826 104.317 35.1826 103.684 35.5732 103.293Z" fill="#6d28d9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.9874 98.8927C48.3779 99.2832 48.3779 99.9163 47.9874 100.307L43.1474 105.147C42.7569 105.537 42.1237 105.537 41.7332 105.147C41.3426 104.756 41.3426 104.123 41.7332 103.733L46.5732 98.8927C46.9637 98.5021 47.5969 98.5021 47.9874 98.8927Z" fill="#6d28d9" />
    </svg>;
};
export default ProjectIcon;